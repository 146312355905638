import { NumberInputProps } from "@mui/base";
import { Box, Typography } from "@mui/material";
import { useIPGeoLocation } from "hooks";
import { ReactNode } from "react";

export const InputAdornment = ({ children }: { children: ReactNode }) => (
  <Box
    className="InputAdornment m-1 inline-flex items-center justify-center"
    sx={{
      color: "var(--palette-action-active)",
      gridRow: "1/3",
    }}
  >
    {children}
  </Box>
);

const Suffix = () => {
  const { location } = useIPGeoLocation();

  return (
    <Typography className="mx-2" variant="p2" color="text.primary">
      / {location.code === "NZ" ? "NZD" : "AUD"}
    </Typography>
  );
};

const priceFieldProps = {
  endAdornment: (
    <InputAdornment>
      <Suffix />
    </InputAdornment>
  ),
} as NumberInputProps;

export { priceFieldProps };
