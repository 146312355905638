// Import assets
import noPreview from "@/assets/images/no-preview.png";

// Import css
import "photoswipe/dist/photoswipe.css";

import { AddCircle, AudioFile, Close, CloudUploadOutlined, Description } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  FormHelperText,
  FormLabel,
  IconButton,
  Slide,
  Typography,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import { Image as CustomNextImage } from "components/shared";
import { showMessage } from "components/shared/layout/store/messageSlice";
import clsx from "lib/clsx";
import { useAppDispatch } from "lib/redux";
import merge from "lodash/merge";
import { IFileUpload, IForm, UploadFile } from "models/form";
import { IFile, IImage } from "models/shared";
import { ReactElement, Ref, forwardRef, useEffect, useMemo, useState } from "react";
import Dropzone, { FileRejection } from "react-dropzone";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Gallery, Item } from "react-photoswipe-gallery";
import { formatBytes } from "utils/format";

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: ReactElement;
  },
  ref: Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface IImagePreviewProps extends Pick<IFileUpload, "caption" | "objectFit"> {
  file: UploadFile | IFile | IImage;
  clear: () => void;
}

type FileType = "image" | "audio" | "video" | "file";

const SingleImagePreview = ({ caption, objectFit, file, clear }: IImagePreviewProps) => {
  const uploadFile = file as UploadFile;
  const contentFile = file as IFile;
  const imageFile = file as IImage;

  const fileType = uploadFile.type ?? contentFile.mime ?? imageFile.mime;

  const type: FileType = useMemo(
    () =>
      fileType?.startsWith("image/")
        ? "image"
        : fileType?.startsWith("audio/")
          ? "audio"
          : fileType?.startsWith("video/")
            ? "video"
            : "file",
    [fileType],
  );
  const src =
    uploadFile.preview ?? process.env.NEXT_PUBLIC_BACKEND_URL + (contentFile.url ?? imageFile.url);

  const [source, setSource] = useState<{
    type: FileType;
    src: string;
    width?: number;
    height?: number;
  }>({
    type,
    src,
  });

  useEffect(() => {
    setSource({ type, src });
  }, [src, type]);

  return (
    <Box
      className={clsx(
        "flex-center group relative h-full w-full flex-col bg-white/40",
        (source.type === "audio" || source.type === "video") && "bg-black/40",
      )}
      sx={merge(
        source.type !== "audio" && source.type !== "video"
          ? {
              "&::after": {
                content: "''",
                position: "absolute",
                inset: 0,
                backgroundColor: "rgba(0,0,0,0.35)",
                backdropFilter: "blur(2px)",
                visibility: "hidden",
                opacity: 0,
                transitionDuration: "500ms",
              },
              "&:hover::after": {
                visibility: "visible",
                opacity: 1,
              },
            }
          : undefined,
        {},
      )}
    >
      {source.type !== "audio" && source.type !== "video" && (
        <AddCircle
          aria-hidden="true"
          className="transform-center absolute z-10 mt-8 aspect-square w-1/3 text-9xl text-dark opacity-0 transition-all duration-500 group-hover:mt-0 group-hover:opacity-100"
        />
      )}
      {source.type === "image" ? (
        <picture className="h-full w-full">
          <img
            className="h-full w-full"
            src={source.src}
            alt={imageFile.alternativeText || `Preview of ${uploadFile.name}`}
            width={source.width}
            height={source.height}
            onLoad={() => {
              if (uploadFile.preview) URL.revokeObjectURL(uploadFile.preview);
            }}
            onError={() => {
              setSource({
                type: "image",
                src: process.env.NEXT_PUBLIC_FRONTEND_URL + noPreview.src,
                width: noPreview.width,
                height: noPreview.height,
              });
            }}
            style={{ objectFit }}
          />
        </picture>
      ) : source.type === "audio" ? (
        <audio
          className="w-full max-w-screen-sm px-2 xs:px-16"
          src={source.src}
          controls
          muted
          playsInline
        />
      ) : source.type === "video" ? (
        <video
          className="h-full w-full"
          src={source.src}
          controls
          muted
          playsInline
          style={{ objectFit }}
        />
      ) : (
        <div className="flex-center m-4 flex-col gap-4">
          <Description className="text-8xl text-gray-600 xs:text-9xl" />
          <Typography className="text-center text-xl font-medium text-gray-600 xs:text-2xl">
            No preview available
          </Typography>
        </div>
      )}
      <IconButton
        aria-label="close"
        className="absolute right-1.5 top-1.5 z-10 bg-black/50 p-2 text-white drop-shadow-md backdrop-blur-sm hover:bg-black/60"
        size="medium"
        color="inherit"
        onClick={(e) => {
          e.preventDefault();
          clear();
        }}
      >
        <Close className="text-2xl" fontSize="small" />
      </IconButton>
      {caption && (uploadFile.name || uploadFile.size) && (
        <Box
          className={clsx(
            "z-10 flex w-full flex-col justify-center overflow-hidden bg-black/60 px-4 py-2 text-white backdrop-blur-sm",
            source.type !== "video" && "absolute bottom-0",
          )}
          sx={{
            backgroundSize: "4px 4px",
            backgroundImage:
              "radial-gradient(rgba(0, 0, 0, 0) 1px, rgba(var(--rgb-primary-main), 0.1) 1px)",
          }}
        >
          {uploadFile.name && (
            <Typography className="line-clamp-2 leading-4" variant="caption">
              {uploadFile.name}
            </Typography>
          )}
          {uploadFile.size && (
            <Typography className="truncate" variant="caption">
              {formatBytes(uploadFile.size, 2)}
            </Typography>
          )}
        </Box>
      )}
    </Box>
  );
};

const MultiImagePreview = ({ caption, objectFit, file, clear }: IImagePreviewProps) => {
  const uploadFile = file as UploadFile;
  const contentFile = file as IFile;
  const imageFile = file as IImage;

  const fileType = uploadFile.type ?? contentFile.mime ?? imageFile.mime;

  const type: FileType = useMemo(
    () =>
      fileType.startsWith("image/")
        ? "image"
        : fileType.startsWith("audio/")
          ? "audio"
          : fileType.startsWith("video/")
            ? "video"
            : "file",
    [fileType],
  );
  const src =
    uploadFile.preview ?? process.env.NEXT_PUBLIC_BACKEND_URL + (contentFile.url ?? imageFile.url);

  const [source, setSource] = useState<{
    type: FileType;
    src: string;
    width?: number;
    height?: number;
  }>({
    type,
    src,
  });
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogOpen = () => {
    if (type !== "file") setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  useEffect(() => {
    if (file && type === "image") {
      const img = new Image();

      const handleLoad = () => {
        const width = img.naturalWidth;
        const height = img.naturalHeight;
        setSource({ type, src, width, height });

        // Clean up the Image
        img.removeEventListener("load", handleLoad);
      };

      img.addEventListener("load", handleLoad);
      img.src = src;
    } else {
      setSource({ type, src });
    }
  }, [file, src, type]);

  return (
    <div
      key={uploadFile.name ?? contentFile.url ?? imageFile.url}
      className="relative flex min-w-24 flex-col gap-1"
    >
      <Dialog
        aria-labelledby="dialog-title"
        open={dialogOpen}
        TransitionComponent={Transition}
        keepMounted={false}
        onClose={handleDialogClose}
        classes={{
          paper: "w-full max-w-screen-xs m-2 xs:m-8 bg-transparent backdrop-blur rounded-3xl",
        }}
      >
        <DialogTitle
          className="flex h-full items-center justify-between gap-2 px-4 xs:px-6"
          color="white"
        >
          <Typography id="dialog-title" className="line-clamp-2">
            {uploadFile.name || imageFile.alternativeText}
          </Typography>
          <IconButton
            aria-label="close"
            className="z-10 bg-white/50 p-2 text-black drop-shadow-md backdrop-blur-sm hover:bg-white/60"
            size="medium"
            color="inherit"
            onClick={(e) => {
              e.preventDefault();
              handleDialogClose();
            }}
          >
            <Close className="text-2xl" fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent className="px-4 xs:px-6">
          {source.type === "audio" ? (
            <audio className="w-full" src={source.src} controls muted playsInline />
          ) : (
            source.type === "video" && (
              <video
                className="h-full w-full"
                src={source.src}
                controls
                muted
                playsInline
                style={{ objectFit }}
              />
            )
          )}
        </DialogContent>
      </Dialog>
      {type === "image" ? (
        <div className="flex-center grow flex-col rounded border">
          <Gallery>
            <Item
              original={source.src}
              thumbnail={source.src}
              width={source.width}
              height={source.height}
              cropped
            >
              {({ ref, open }) => (
                <div className="w-full cursor-zoom-in" ref={ref} onClick={open}>
                  <div className="m-auto h-fit w-fit">
                    <CustomNextImage
                      className="h-fit max-h-24 w-fit"
                      url={source.src}
                      alternativeText={imageFile.alternativeText || `Preview of ${uploadFile.name}`}
                      width={160}
                      height={90}
                      onLoadedData={() => {
                        if (uploadFile.preview) URL.revokeObjectURL(uploadFile.preview);
                      }}
                      onError={() => {
                        setSource({
                          type: "image",
                          src: process.env.NEXT_PUBLIC_FRONTEND_URL + noPreview.src,
                          width: noPreview.width,
                          height: noPreview.height,
                        });
                      }}
                      style={{ objectFit }}
                    />
                  </div>
                </div>
              )}
            </Item>
          </Gallery>
        </div>
      ) : (
        <div
          className={clsx(
            "flex-center max-h-24 grow flex-col overflow-hidden rounded border p-1",
            type !== "file" && "cursor-pointer",
          )}
          onClick={handleDialogOpen}
        >
          {type === "audio" || type === "video" ? (
            <>
              {source.type === "audio" ? (
                <AudioFile className="text-5xl text-gray-600" />
              ) : (
                source.type === "video" && (
                  <video
                    className="pointer-events-none"
                    src={source.src}
                    tabIndex={-1}
                    preload="metadata"
                    width={160}
                    height={90}
                    style={{ objectFit }}
                  />
                )
              )}
            </>
          ) : (
            <Description className="text-5xl text-gray-600" />
          )}
        </div>
      )}
      {caption && (
        <>
          <Typography className="line-clamp-2 leading-none" variant="caption">
            {uploadFile.name ?? imageFile.caption ?? imageFile.alternativeText ?? type}
          </Typography>
          <Typography className="truncate" variant="caption">
            {uploadFile.size
              ? formatBytes(uploadFile.size, 2)
              : imageFile.width && imageFile.height
                ? `${imageFile.width} x ${imageFile.height}`
                : imageFile.mime}
          </Typography>
        </>
      )}
      <IconButton
        aria-label="close"
        className="absolute right-1.5 top-1.5 bg-black/30 p-1.5 text-white drop-shadow-md backdrop-blur-sm hover:bg-black/40"
        size="small"
        color="inherit"
        onClick={clear}
      >
        <Close className="text-xl" fontSize="small" />
      </IconButton>
    </div>
  );
};

const FileUpload = ({
  name,
  label,
  helperText,
  required,
  color,
  size,
  styles,
  caption,
  objectFit,
  options: fileUploadOptions,
}: IFileUpload) => {
  const dispatch = useAppDispatch();
  const { control } = useFormContext<IForm<(UploadFile | IFile)[]>>();

  const fieldValue: (UploadFile | IFile)[] = useWatch({ name });
  const files = useMemo((): (UploadFile | IFile)[] => {
    const files = Array.isArray(fieldValue)
      ? fieldValue.filter((f) => !!(f as UploadFile).preview || !!(f as IFile).url)
      : fieldValue
        ? [fieldValue]
        : [];

    const filesWithPreview = files.map((f) => {
      URL.revokeObjectURL((f as UploadFile).preview);

      return Object.assign(f, {
        preview: URL.createObjectURL(f as UploadFile),
      });
    });
    return filesWithPreview;
  }, [fieldValue]);
  const options = {
    ...fileUploadOptions,
    minSize: fileUploadOptions.minSize ? +fileUploadOptions.minSize : undefined,
    maxSize: fileUploadOptions.maxSize ? +fileUploadOptions.maxSize : undefined,
  };
  const maxFiles = options.maxFiles ?? Number.POSITIVE_INFINITY;

  const onDropAccepted = (acceptedFiles: File[]) => {
    if (maxFiles === 1) {
      const file = acceptedFiles[0] as UploadFile;

      URL.revokeObjectURL(file.preview);

      return [
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ];
    }

    return [
      ...files,
      ...acceptedFiles.reduce((fileQueue: UploadFile[], file) => {
        if (files.length + fileQueue.length >= maxFiles) return fileQueue;

        URL.revokeObjectURL((file as UploadFile).preview);

        return [
          ...fileQueue,
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ];
      }, []),
    ];
  };

  const onDropRejected = (fileRejections: FileRejection[]) => {
    const distinctErrors = fileRejections
      .flatMap((rej) =>
        rej.errors.filter((err, i, arr) => arr.findIndex((t) => t.code === err.code) === i),
      )
      .filter((err, i, arr) => arr.findIndex((t) => t.code === err.code) === i);

    distinctErrors.map((rej) => {
      const message =
        rej.code === "too-many-files"
          ? `Select upto ${maxFiles} files only`
          : rej.code === "file-too-large" && options.maxSize
            ? `File is larger than ${formatBytes(options.maxSize, 2)}`
            : rej.code === "file-too-small" && options.minSize
              ? `File is smaller than ${formatBytes(options.minSize, 2)}`
              : rej.message;

      dispatch(
        showMessage({
          message,
          variant: "warning",
        }),
      );
    });
  };

  const validator = (file: File) => {
    if (files.findIndex((f) => (f as UploadFile).name === file.name) !== -1) {
      return {
        code: "file-already-exists",
        message: "The selected file(s) already exists",
      };
    }

    if (maxFiles !== 1 && files.length >= maxFiles) {
      return {
        code: "max-files-selected",
        message: "Maximum number of items have been selected",
      };
    }

    return null;
  };

  const dropzoneOptions = {
    ...options,
    noClick: true, //? https://github.com/react-dropzone/react-dropzone/issues/1107
    onDropRejected,
    validator,
  };

  const handleRemoveFile = (src: string) => {
    const fileRemoving = files.find((f) => ((f as UploadFile).preview ?? (f as IFile).url) === src);
    const rest = files.filter((f) => ((f as UploadFile).preview ?? (f as IFile).url) !== src);

    const url = (fileRemoving as UploadFile)?.preview;
    if (url) URL.revokeObjectURL(url);
    return rest;
  };

  // useUnmountEffect(() => {
  //   // Make sure to revoke the data urls to avoid memory leaks, will run on unmount
  //   files.forEach((file) => {
  //     const url = (file as UploadFile).preview;
  //     if (url) URL.revokeObjectURL(url);
  //   });
  // });

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { value, onChange }, formState: { errors } }) => {
        value = Array.isArray(value) ? value : value ? [value] : [];

        return (
          <Box sx={styles}>
            <div className="flex h-full flex-col gap-3">
              {label && (
                <FormLabel className="flex text-wrap text-light" required={required}>
                  <Typography variant="p1">{label}</Typography>
                </FormLabel>
              )}
              <Dropzone
                {...dropzoneOptions}
                onDropAccepted={(files) => {
                  setTimeout(() => {
                    //? react-hook-form related issue in useIsFormValidToSubmit which prevents the value being validated w/o delay
                    onChange(onDropAccepted(files));
                  }, 0);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <Box
                    id="dropzone"
                    htmlFor={name}
                    className={clsx(
                      "flex-center group relative h-full w-full cursor-pointer overflow-hidden rounded-lg border-2 border-dotted bg-background-paper duration-300 hover:bg-background-paper/60",
                      errors[name] && "border-red-500",
                    )}
                    component="label"
                    {...getRootProps()}
                    color={(theme) => theme.palette[color].main}
                  >
                    {maxFiles === 1 && value.length === 1 ? (
                      <SingleImagePreview
                        caption={caption}
                        objectFit={objectFit}
                        file={value[0]}
                        clear={() => {
                          const url = (value[0] as UploadFile)?.preview ?? (value[0] as IFile)?.url;
                          setTimeout(() => {
                            onChange(handleRemoveFile(url));
                          }, 0);
                        }}
                      />
                    ) : (
                      <div className="flex h-72 flex-col items-center justify-center px-2 py-5">
                        <AddCircle className="transform-center absolute scale-100 text-9xl opacity-100 transition-all delay-150 duration-300 group-hover:scale-50 group-hover:opacity-0 group-hover:delay-0" />
                        <div className="transform-center absolute mt-12 flex flex-col items-center opacity-0 delay-150 duration-300 group-hover:mt-0 group-hover:opacity-100">
                          <CloudUploadOutlined className="mb-3" fontSize="large" />
                          <Typography className="mb-2 text-center" variant="p3">
                            <Typography component="span" className="font-semibold" variant="p2">
                              Click to upload
                            </Typography>{" "}
                            or drag and drop
                          </Typography>
                          <div className="flex-center flex-col flex-wrap gap-1">
                            <Typography className="text-center uppercase" variant="caption">
                              {(() => {
                                const types = Object.keys(options.accept ?? {}).map((key) =>
                                  key.split("/")[1].toUpperCase(),
                                );

                                return types.length === 0
                                  ? "ALL FILES (*.*)"
                                  : types.length > 1
                                    ? types.slice(0, -1).join(", ") + " or " + types.slice(-1)
                                    : types.join("");
                              })()}
                            </Typography>
                            <Typography className="text-center uppercase" variant="caption">
                              {options.maxSize || options.maxFiles
                                ? `(${
                                    options.maxSize ? `MAX. ${formatBytes(options.maxSize, 2)}` : ""
                                  }${options.maxSize && options.maxFiles ? " " : ""}${
                                    options.maxFiles
                                      ? `UPTO ${options.maxFiles} FILE${
                                          options.maxFiles > 1 ? "S" : ""
                                        }`
                                      : ""
                                  })`
                                : ""}
                            </Typography>
                          </div>
                        </div>
                      </div>
                    )}
                    <input
                      id={name}
                      className="absolute left-0 top-0 -z-10 !block opacity-0"
                      type="file"
                      required={value.length > 0 ? false : required}
                      {...getInputProps({
                        onClick: (e) => {
                          e.currentTarget.value = "";
                        },
                      })}
                    />
                  </Box>
                )}
              </Dropzone>
              {maxFiles > 1 && value.length > 0 && (
                <aside id="multi-image-preview" className="flex flex-wrap gap-2">
                  {value.map((file, i) => {
                    const url = (file as UploadFile)?.preview ?? (file as IFile)?.url;

                    return (
                      <MultiImagePreview
                        key={(url ?? "") + i}
                        caption={caption}
                        objectFit={objectFit}
                        file={file}
                        clear={() => {
                          setTimeout(() => {
                            onChange(handleRemoveFile(url));
                          }, 0);
                        }}
                      />
                    );
                  })}
                </aside>
              )}
            </div>
            {(helperText || errors[name]) && (
              <FormHelperText
                className="mt-1.5 leading-tight text-light"
                error={!!errors[name]}
                variant="standard"
              >
                {errors[name]?.message || helperText}
              </FormHelperText>
            )}
          </Box>
        );
      }}
    />
  );
};

export default FileUpload;
