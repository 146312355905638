import { Box, FormHelperText, InputLabel, Typography, useTheme } from "@mui/material";
import clsx from "lib/clsx";
import { IForm, IOTPField } from "models/form";
import { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import OtpInput from "react-otp-input";

const OTPField = ({
  name,
  label,
  required,
  disabled,
  color,
  size,
  styles,
  numInputs,
  inputType,
}: IOTPField) => {
  const {
    control,
    formState: { errors },
  } = useFormContext<IForm<string>>();
  const fieldContainerRef = useRef<HTMLDivElement>(null);

  const theme = useTheme();

  useEffect(() => {
    const inputs = fieldContainerRef.current?.querySelectorAll("input");
    if (inputs?.length && required)
      inputs.forEach((input) => {
        input.required = true;
      });
  }, [required]);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field: { ref, ...field } }) => (
        <Box className="w-fit" sx={styles}>
          <div ref={fieldContainerRef} className="space-y-3">
            {label && (
              <InputLabel className="flex text-wrap text-light" htmlFor={name} required={required}>
                <Typography variant="p1">{label}</Typography>
              </InputLabel>
            )}
            <OtpInput
              containerStyle="otp-field flex-wrap gap-x-6 gap-y-3"
              inputStyle={clsx(disabled && "border-none", "rounded-md")}
              {...field}
              inputType={inputType}
              numInputs={numInputs}
              shouldAutoFocus={!disabled}
              renderInput={(inputProps) => (
                <Box
                  component="input"
                  {...inputProps}
                  color={color}
                  type={inputType === "number" ? "text" : inputType}
                  disabled={disabled}
                  sx={{
                    fontSize: theme.typography.p2.fontSize,
                    minHeight: size === "medium" ? "50px" : "40.5px",
                    minWidth: size === "medium" ? "34px" : "25px",
                    color: disabled ? "text.disabled" : "text.primary",
                    borderColor: !!errors[name]
                      ? "var(--palette-error-main)"
                      : `var(--palette-${color}-main)`,
                    backgroundColor: disabled ? "action.disabledBackground" : undefined,
                  }}
                />
              )}
            />
          </div>
          {errors[name] && (
            <FormHelperText error variant="standard">
              {errors[name]?.message}
            </FormHelperText>
          )}
        </Box>
      )}
    />
  );
};

export default OTPField;
