import { NumberInputProps } from "@mui/base";
import { Typography } from "@mui/material";
import { InputAdornment } from "./PriceField";

const yearsFieldProps = {
  endAdornment: (
    <InputAdornment>
      <Typography className="mx-2" variant="p2" color="text.primary">
        / Years
      </Typography>
    </InputAdornment>
  ),
} as NumberInputProps;

export { yearsFieldProps };
