import { yupResolver } from "@hookform/resolvers/yup";
import { useDeepCompareEffect } from "hooks";
import { createYupSchema } from "lib/yup";
import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import set from "lodash/set";
import { FormField, IAutoComplete, IForm } from "models/form";
import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { FormProvider as RHFFormProvider, UseFormProps, useForm } from "react-hook-form";

const getEmptyDefaultValues = (fields: FormField[]) => {
  const emptyDefaultValues = {};
  fields.forEach((f) => {
    const value =
      (f.type === "ComponentFormAutoComplete" && (f as IAutoComplete).multiple) ||
      f.type === "ComponentFormCheckboxGroup" ||
      f.type === "ComponentFormFileUpload"
        ? []
        : f.type === "ComponentFormNumber"
          ? null
          : "";

    set(emptyDefaultValues, f.name, value);
  });

  return emptyDefaultValues;
};

interface IProps {
  fields: FormField[];
  defaultValues?: any;
  options?: { triggerRevalidateAfterInit: boolean };
  children: ReactNode;
}

const FormFieldsContext = createContext<{
  fields: FormField[];
  setFields: Dispatch<SetStateAction<FormField[]>>;
}>({
  fields: [],
  setFields: () => {},
});

export const useFormFields = () => useContext(FormFieldsContext);

const FormProvider = ({ fields: initialFields, defaultValues, options, children }: IProps) => {
  const { triggerRevalidateAfterInit = false } = options || {};
  const [fields, setFields] = useState(initialFields);

  useEffect(() => {
    if (!fields.length && !!initialFields.length) {
      setFields(initialFields);
    }
  }, [fields.length, initialFields]);

  /**
   * Form Validation Schema
   */
  const validationFields = useMemo(() => fields.filter((f) => f.validationType), [fields]);
  const schema = useMemo(() => createYupSchema(validationFields), [validationFields]);

  const formProps: UseFormProps<IForm> = {
    mode: "onChange",
    defaultValues: getEmptyDefaultValues(fields),
    resolver: yupResolver(schema),
  };
  const methods = useForm<IForm>(formProps);

  defaultValues = useMemo(() => {
    if (defaultValues) {
      const defaultValuesFormatted = {};

      fields.forEach((f) => {
        let value = get(defaultValues, f.name);
        if (typeof value !== "boolean" && !value) {
          value =
            (f.type === "ComponentFormAutoComplete" && (f as IAutoComplete).multiple) ||
            f.type === "ComponentFormCheckboxGroup" ||
            f.type === "ComponentFormFileUpload"
              ? []
              : f.type === "ComponentFormNumber"
                ? null
                : "";
        } else {
          if (f.type === "ComponentFormAutoComplete") {
            if (!(f as IAutoComplete).multiple) {
              value = value.toString();
            }
          }
        }

        set(defaultValuesFormatted, f.name, value);
      });

      return defaultValuesFormatted;
    }

    const currentValues = methods.getValues();

    if (!isEmpty(currentValues)) {
      return currentValues;
    }

    return getEmptyDefaultValues(fields);
  }, [defaultValues, fields, methods]);

  useDeepCompareEffect(() => {
    methods.reset(defaultValues, { keepDirtyValues: true });
    if (triggerRevalidateAfterInit) methods.trigger();
  }, [defaultValues, methods, triggerRevalidateAfterInit]);

  return (
    <FormFieldsContext.Provider value={{ fields, setFields }}>
      <RHFFormProvider {...methods}>{children}</RHFFormProvider>
    </FormFieldsContext.Provider>
  );
};

export default FormProvider;
