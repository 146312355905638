import {
  Box,
  InputAdornment,
  InputLabel,
  TextField as MuiTextField,
  Typography,
  useTheme,
} from "@mui/material";
import { Button, Icon } from "components/shared";
import clsx from "lib/clsx";
import get from "lodash/get";
import { IForm, ITextArea, ITextField } from "models/form";
import { useEffect, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useDefaultBy } from "./hooks";

const TextField = ({
  type,
  name,
  label,
  helperText,
  required,
  disabled,
  color,
  size,
  icon,
  styles,
  fieldType,
  variant,
  title,
  placeholder,
  defaultByGroups,
  autoComplete,
  rows,
}: ITextField & ITextArea) => {
  const {
    control,
    resetField,
    formState: { errors },
  } = useFormContext<IForm<string>>();

  const theme = useTheme();

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  //#region Set defaultBy
  const defaultByValue = useDefaultBy<string>(defaultByGroups);
  const watchValues = useWatch();
  const currentValue = get(watchValues, name);

  useEffect(() => {
    const currentValueEqualsDefault = currentValue === defaultByValue;
    if (currentValueEqualsDefault || (!currentValue && !defaultByValue)) return;
    resetField(name, { defaultValue: currentValue || defaultByValue, keepDirty: true });
  }, [defaultByValue, currentValue, name, resetField]);
  //#endregion

  const error = get(errors, name);

  return (
    <Controller
      name={name}
      control={control}
      defaultValue=""
      render={({ field }) => (
        <Box className="space-y-4" sx={styles}>
          {label && (
            <InputLabel className="flex text-wrap text-light" htmlFor={name} required={required}>
              <Typography variant="p1">{label}</Typography>
            </InputLabel>
          )}
          <MuiTextField
            {...field}
            className={clsx("overflow-hidden text-light", variant === "outlined" && "rounded-md")}
            type={fieldType === "password" && !showPassword ? fieldType : "text"}
            name={name}
            hiddenLabel
            placeholder={
              !required ? (placeholder ? `${placeholder} (optional)` : "(optional)") : placeholder
            }
            autoComplete={autoComplete}
            required={required}
            disabled={disabled}
            size={size}
            variant={variant}
            color={color}
            focused={variant === "outlined" || undefined}
            fullWidth
            multiline={type === "ComponentFormTextArea"}
            rows={rows}
            error={!!error}
            helperText={error?.message || helperText}
            slotProps={{
              formHelperText: {
                className: "mx-0 text-inherit",
              },
              input: {
                componentsProps: !label ? { input: { "aria-label": title } } : undefined,
                startAdornment: icon?.url && (
                  <InputAdornment position="start">
                    <Icon
                      className="flex h-auto w-6 items-center"
                      icon={icon}
                      sx={{
                        svg: {
                          display: "flex",
                          alignItems: "center",
                          width: "15px",
                          height: "auto",
                        },
                      }}
                    />
                  </InputAdornment>
                ),
                endAdornment: fieldType === "password" && (
                  <InputAdornment position="end">
                    <Button
                      aria-label="toggle password visibility"
                      className="h-12 p-4 capitalize"
                      onClick={handleClickShowPassword}
                      onMouseDown={(e) => {
                        e.preventDefault();
                      }}
                      size="small"
                      disabled={disabled}
                      sx={{
                        color: disabled ? `var(--palette-text-disabled)!important` : undefined,
                      }}
                    >
                      {!showPassword ? "Show" : "Hide"}
                    </Button>
                  </InputAdornment>
                ),
              },
            }}
            sx={{
              "& .MuiInputBase-root": {
                color: "inherit",
                fontWeight: "inherit",
                fontSize: theme.typography.p2.fontSize,
                px: variant !== "standard" ? "10px" : undefined,
              },
              "& .MuiOutlinedInput-root fieldset": {
                borderWidth: "2px",
                borderTopWidth: "3.5px !important",
                borderColor: disabled
                  ? `var(--palette-text-disabled)!important`
                  : `var(--palette-${color}-main)`,
              },
              "& input": {
                py: size === "medium" ? "12.07px" : "7.32px",
                px: variant !== "standard" ? "5px" : undefined,
                overflow: "hidden",
                textOverflow: "ellipsis",
                "&::placeholder": {
                  opacity: 1,
                  color: "var(--palette-primary-main)",
                },
              },
              "& .Mui-focused .MuiInputAdornment-root svg": {
                color: `var(--palette-${color}-main)`,
              },
            }}
          />
        </Box>
      )}
    />
  );
};

export default TextField;
