import { NumberInputProps } from "@mui/base";
import { Typography } from "@mui/material";
import { InputAdornment } from "./PriceField";

const percentageFieldProps = {
  endAdornment: (
    <InputAdornment>
      <Typography className="mx-2" variant="p2" color="text.primary">
        / %
      </Typography>
    </InputAdornment>
  ),
} as NumberInputProps;

export { percentageFieldProps };
